import React, { Suspense } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

import { useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Loading from 'src/components/Loading'
import 'src/scss/style.scss'

// Containers
const DefaultLayout = React.lazy(() => import('src/components/layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('src/pages/Login'))

const queryClient = new QueryClient()

const App = () => {
  const { isLoggedIn } = useSelector(({ root }) => root.authReducer)

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            {isLoggedIn ? (
              <Route path="*" name="Home" element={<DefaultLayout />} />
            ) : (
              <Route path="*" element={<Navigate to="login" replace />} />
            )}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
