import { combineReducers } from '@reduxjs/toolkit'

const reducers = {}

// get all reducers in "./reducers" directory so we don't need to import 1 by 1 each time to include in combineReducers
const files = require.context('./reducers', true, /\.js$/)

files.keys().forEach((key) => {
  const filenameWithoutExtension = key.replace(/^.+\/|\.\w+$/g, '')
  reducers[filenameWithoutExtension] = files(key).default
})

const rootReducer = combineReducers(reducers)

export default rootReducer
