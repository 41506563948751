import { createSlice } from '@reduxjs/toolkit'

const jobsSlice = createSlice({
  name: 'jobs',
  initialState: {
    isLoading: false,
    jobsData: [],
    query: '',
    searchedJobsData: [],
    filteredJobsData: [],
  },
  reducers: {
    jobsToggleLoading: (state, action) => {
      state.isLoading = action?.payload || false
    },
    setJobs: (state, action) => {
      state.jobsData = action?.payload || []
      state.isLoading = false
    },
    setJobsQuery: (state, action) => {
      state.query = action?.payload || ''
    },
    searchJobs: (state, action) => {
      state.searchedJobsData = action?.payload || []
      state.isLoading = false
    },
    filterJobs: (state, action) => {
      state.filteredJobsData = action?.payload || []
      state.isLoading = false
    },
  },
})

export const { jobsToggleLoading, setJobs, setJobsQuery, searchJobs, filterJobs } =
  jobsSlice.actions

export default jobsSlice.reducer
