import { createSlice } from '@reduxjs/toolkit'

const employersSlice = createSlice({
  name: 'employers',
  initialState: {
    isLoading: false,
    employersData: [],
    query: '',
    searchedEmployersData: [],
    filteredEmployersData: [],
  },
  reducers: {
    employersToggleLoading: (state, action) => {
      state.isLoading = action?.payload || false
    },
    setEmployers: (state, action) => {
      state.employersData = action?.payload || []
      state.isLoading = false
    },
    setEmployersQuery: (state, action) => {
      state.query = action?.payload || ''
    },
    searchEmployers: (state, action) => {
      state.searchedEmployersData = action?.payload || []
      state.isLoading = false
    },
    filterEmployers: (state, action) => {
      state.filteredEmployersData = action?.payload || []
      state.isLoading = false
    },
  },
})

export const {
  employersToggleLoading,
  setEmployers,
  setEmployersQuery,
  searchEmployers,
  filterEmployers,
} = employersSlice.actions

export default employersSlice.reducer
