// import { persistStore } from 'redux-persist'
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'
// import { persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
// import authReducer from './reducers/authReducer'

// const serializer = {
//   serialize: (value) => JSON.stringify(value),
//   deserialize: (value) => JSON.parse(value),
// }

// const authPersistConfig = {
//   key: 'auth',
//   storage: storage,
//   serialize: serializer,
//   deserialize: serializer,
// }

const store = configureStore({
  reducer: {
    // auth: persistReducer(authPersistConfig, authReducer),
    root: rootReducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// const persistor = persistStore(store)

// export { store, persistor }
export { store }
