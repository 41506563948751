import { createSlice } from '@reduxjs/toolkit'

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    isSidebarShown: true,
    isSidebarFolded: false,
    isSidebarFoldedVisible: true,
  },
  reducers: {
    sidebarToggleShow: (state, action) => {
      state.isSidebarShown = action.payload ? action.payload : !state.isSidebarShown
    },
    sidebarToggleFold: (state, action) => {
      state.isSidebarFolded = action.payload ? action.payload : !state.isSidebarFolded
    },
  },
})

export const { sidebarToggleShow, sidebarToggleFold } = sidebarSlice.actions

export default sidebarSlice.reducer
