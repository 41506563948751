import { createSlice } from '@reduxjs/toolkit'

const tokensSlice = createSlice({
  name: 'tokens',
  initialState: {
    isLoading: false,
    tokensData: [],
    query: '',
    searchedTokensData: [],
    filteredTokensData: [],
  },
  reducers: {
    tokensToggleLoading: (state, action) => {
      state.isLoading = action?.payload || false
    },
    setTokens: (state, action) => {
      state.tokensData = action?.payload || []
      state.isLoading = false
    },
    setTokensQuery: (state, action) => {
      state.query = action?.payload || ''
    },
    searchTokens: (state, action) => {
      state.searchedTokensData = action?.payload || []
      state.isLoading = false
    },
    filterTokens: (state, action) => {
      state.filteredTokensData = action?.payload || []
      state.isLoading = false
    },
  },
})

export const { tokensToggleLoading, setTokens, setTokensQuery, searchTokens, filterTokens } =
  tokensSlice.actions

export default tokensSlice.reducer
