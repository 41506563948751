import { createSlice } from '@reduxjs/toolkit'

const userSession = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : {}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    user: null,
    ...userSession,
  },
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true
      state.user = { ...action.payload, ...state.user }
      sessionStorage.setItem('user', JSON.stringify(state))
    },
    logout: (state) => {
      state.isLoggedIn = false
      state.user = null
      sessionStorage.removeItem('user')
    },
  },
})

export const { login, logout } = authSlice.actions

export default authSlice.reducer
