import React from 'react'
import { CSpinner } from '@coreui/react'

const Loading = () => (
  <div
    style={{
      position: 'absolute',
      top: '50%',
      left: '55%',
      transform: 'translate(-50%, -50%)',
    }}
  >
    <div className="d-flex justify-content-center align-items-center">
      <CSpinner color="primary" />
    </div>
  </div>
)

export default Loading
