import { createSlice } from '@reduxjs/toolkit'

const utilsSlice = createSlice({
  name: 'utils',
  initialState: {
    phoneNumbersData: [],
    creativesGoalsData: [],
    creativesTopicsData: [],
  },
  reducers: {
    setPhoneNumbersData: (state, action) => {
      state.phoneNumbersData = action?.payload || []
    },
    setCreativesGoalsData: (state, action) => {
      state.creativesGoalsData = action?.payload || []
    },
    setCreativesTopicsData: (state, action) => {
      state.creativesTopicsData = action?.payload || []
    },
    setEmployerIndustries: (state, action) => {
      state.employerIndustries = action?.payload || []
    },
    setLocationsData: (state, action) => {
      state.locationsData = action?.payload || []
    },
  },
})

export const {
  setPhoneNumbersData,
  setCreativesGoalsData,
  setCreativesTopicsData,
  setEmployerIndustries,
  setLocationsData,
} = utilsSlice.actions

export default utilsSlice.reducer
