import { createSlice } from '@reduxjs/toolkit'

const couponsSlice = createSlice({
  name: 'coupons',
  initialState: {
    isLoading: false,
    couponsData: [],
    query: '',
    searchedCouponsData: [],
    filteredCouponsData: [],
  },
  reducers: {
    couponsToggleLoading: (state, action) => {
      state.isLoading = action?.payload || false
    },
    setCoupons: (state, action) => {
      state.couponsData = action?.payload || []
      state.isLoading = false
    },
    setCouponsQuery: (state, action) => {
      state.query = action?.payload || ''
    },
    searchCoupons: (state, action) => {
      state.searchedCouponsData = action?.payload || []
      state.isLoading = false
    },
    filterCoupons: (state, action) => {
      state.filteredCouponsData = action?.payload || []
      state.isLoading = false
    },
  },
})

export const { couponsToggleLoading, setCoupons, setCouponsQuery, searchCoupons, filterCoupons } =
  couponsSlice.actions

export default couponsSlice.reducer
